@mixin primer-colors-light_high_contrast {
  & {
    --color-canvas-default-transparent: rgba(255,255,255,0);
    --color-page-header-bg: #ffffff;
    --color-marketing-icon-primary: #1168e3;
    --color-marketing-icon-secondary: #368cf9;
    --color-diff-blob-addition-num-text: #0e1116;
    --color-diff-blob-addition-fg: #ffffff;
    --color-diff-blob-addition-num-bg: #ccffd8;
    --color-diff-blob-addition-line-bg: #e6ffec;
    --color-diff-blob-addition-word-bg: #055d20;
    --color-diff-blob-deletion-num-text: #0e1116;
    --color-diff-blob-deletion-fg: #ffffff;
    --color-diff-blob-deletion-num-bg: #ffd7d5;
    --color-diff-blob-deletion-line-bg: #fff0ee;
    --color-diff-blob-deletion-word-bg: #a0111f;
    --color-diff-blob-hunk-num-bg: #9cd7ff;
    --color-diff-blob-expander-icon: #0e1116;
    --color-diff-blob-selected-line-highlight-mix-blend-mode: multiply;
    --color-diffstat-deletion-border: rgba(1,4,9,0.8);
    --color-diffstat-addition-border: rgba(1,4,9,0.8);
    --color-diffstat-addition-bg: #055d20;
    --color-search-keyword-hl: #fcf7be;
    --color-prettylights-syntax-comment: #66707b;
    --color-prettylights-syntax-constant: #023b95;
    --color-prettylights-syntax-entity: #622cbc;
    --color-prettylights-syntax-storage-modifier-import: #0e1116;
    --color-prettylights-syntax-entity-tag: #024c1a;
    --color-prettylights-syntax-keyword: #a0111f;
    --color-prettylights-syntax-string: #032563;
    --color-prettylights-syntax-variable: #702c00;
    --color-prettylights-syntax-brackethighlighter-unmatched: #6e011a;
    --color-prettylights-syntax-invalid-illegal-text: #ffffff;
    --color-prettylights-syntax-invalid-illegal-bg: #6e011a;
    --color-prettylights-syntax-carriage-return-text: #ffffff;
    --color-prettylights-syntax-carriage-return-bg: #a0111f;
    --color-prettylights-syntax-string-regexp: #024c1a;
    --color-prettylights-syntax-markup-list: #2e1800;
    --color-prettylights-syntax-markup-heading: #023b95;
    --color-prettylights-syntax-markup-italic: #0e1116;
    --color-prettylights-syntax-markup-bold: #0e1116;
    --color-prettylights-syntax-markup-deleted-text: #6e011a;
    --color-prettylights-syntax-markup-deleted-bg: #fff0ee;
    --color-prettylights-syntax-markup-inserted-text: #024c1a;
    --color-prettylights-syntax-markup-inserted-bg: #d2fedb;
    --color-prettylights-syntax-markup-changed-text: #702c00;
    --color-prettylights-syntax-markup-changed-bg: #ffc67b;
    --color-prettylights-syntax-markup-ignored-text: #e7ecf0;
    --color-prettylights-syntax-markup-ignored-bg: #023b95;
    --color-prettylights-syntax-meta-diff-range: #622cbc;
    --color-prettylights-syntax-brackethighlighter-angle: #4b535d;
    --color-prettylights-syntax-sublimelinter-gutter-mark: #88929d;
    --color-prettylights-syntax-constant-other-reference-link: #032563;
    --color-codemirror-text: #0e1116;
    --color-codemirror-bg: #ffffff;
    --color-codemirror-gutters-bg: #ffffff;
    --color-codemirror-guttermarker-text: #ffffff;
    --color-codemirror-guttermarker-subtle-text: #66707b;
    --color-codemirror-linenumber-text: #0e1116;
    --color-codemirror-cursor: #0e1116;
    --color-codemirror-selection-bg: #368cf9;
    --color-codemirror-activeline-bg: #e7ecf0;
    --color-codemirror-matchingbracket-text: #0e1116;
    --color-codemirror-lines-bg: #ffffff;
    --color-codemirror-syntax-comment: #0e1116;
    --color-codemirror-syntax-constant: #023b95;
    --color-codemirror-syntax-entity: #622cbc;
    --color-codemirror-syntax-keyword: #a0111f;
    --color-codemirror-syntax-storage: #a0111f;
    --color-codemirror-syntax-string: #032563;
    --color-codemirror-syntax-support: #023b95;
    --color-codemirror-syntax-variable: #702c00;
    --color-checks-bg: #0e1116;
    --color-checks-run-border-width: 0px;
    --color-checks-container-border-width: 0px;
    --color-checks-text-primary: #ffffff;
    --color-checks-text-secondary: #88929d;
    --color-checks-text-link: #368cf9;
    --color-checks-btn-icon: #acb6c0;
    --color-checks-btn-hover-icon: #ffffff;
    --color-checks-btn-hover-bg: rgba(255,255,255,0.125);
    --color-checks-input-text: #e7ecf0;
    --color-checks-input-placeholder-text: #88929d;
    --color-checks-input-focus-text: #88929d;
    --color-checks-input-bg: #20252c;
    --color-checks-input-shadow: none;
    --color-checks-donut-error: #d5232c;
    --color-checks-donut-pending: #956400;
    --color-checks-donut-success: #055d20;
    --color-checks-donut-neutral: #acb6c0;
    --color-checks-dropdown-text: #acb6c0;
    --color-checks-dropdown-bg: #20252c;
    --color-checks-dropdown-border: #343b43;
    --color-checks-dropdown-shadow: rgba(1,4,9,0.3);
    --color-checks-dropdown-hover-text: #ffffff;
    --color-checks-dropdown-hover-bg: #343b43;
    --color-checks-dropdown-btn-hover-text: #ffffff;
    --color-checks-dropdown-btn-hover-bg: #20252c;
    --color-checks-scrollbar-thumb-bg: #4b535d;
    --color-checks-header-label-text: #ced5dc;
    --color-checks-header-label-open-text: #ffffff;
    --color-checks-header-border: #20252c;
    --color-checks-header-icon: #88929d;
    --color-checks-line-text: #ced5dc;
    --color-checks-line-num-text: rgba(136,146,157,0.75);
    --color-checks-line-timestamp-text: #88929d;
    --color-checks-line-hover-bg: #20252c;
    --color-checks-line-selected-bg: rgba(17,104,227,0.15);
    --color-checks-line-selected-num-text: #368cf9;
    --color-checks-line-dt-fm-text: #0e1116;
    --color-checks-line-dt-fm-bg: #744500;
    --color-checks-gate-bg: rgba(96,55,0,0.15);
    --color-checks-gate-text: #ced5dc;
    --color-checks-gate-waiting-text: #b58407;
    --color-checks-step-header-open-bg: #20252c;
    --color-checks-step-error-text: #ee5a5d;
    --color-checks-step-warning-text: #b58407;
    --color-checks-logline-text: #88929d;
    --color-checks-logline-num-text: rgba(136,146,157,0.75);
    --color-checks-logline-debug-text: #a371f7;
    --color-checks-logline-error-text: #ced5dc;
    --color-checks-logline-error-num-text: #ee5a5d;
    --color-checks-logline-error-bg: rgba(134,6,29,0.15);
    --color-checks-logline-warning-text: #ced5dc;
    --color-checks-logline-warning-num-text: #b58407;
    --color-checks-logline-warning-bg: rgba(96,55,0,0.15);
    --color-checks-logline-command-text: #368cf9;
    --color-checks-logline-section-text: #26a148;
    --color-checks-ansi-black: #0e1116;
    --color-checks-ansi-black-bright: #20252c;
    --color-checks-ansi-white: #ced5dc;
    --color-checks-ansi-white-bright: #ced5dc;
    --color-checks-ansi-gray: #88929d;
    --color-checks-ansi-red: #ee5a5d;
    --color-checks-ansi-red-bright: #ff8e8a;
    --color-checks-ansi-green: #26a148;
    --color-checks-ansi-green-bright: #43c663;
    --color-checks-ansi-yellow: #b58407;
    --color-checks-ansi-yellow-bright: #d5a824;
    --color-checks-ansi-blue: #368cf9;
    --color-checks-ansi-blue-bright: #67b3fd;
    --color-checks-ansi-magenta: #a371f7;
    --color-checks-ansi-magenta-bright: #c49bff;
    --color-checks-ansi-cyan: #76e3ea;
    --color-checks-ansi-cyan-bright: #b3f0ff;
    --color-project-header-bg: #0e1116;
    --color-project-sidebar-bg: #ffffff;
    --color-project-gradient-in: #ffffff;
    --color-project-gradient-out: rgba(255,255,255,0);
    --color-mktg-btn-bg: #1b1f23;
    --color-mktg-btn-shadow-outline: rgb(0 0 0 / 15%) 0 0 0 1px inset;
    --color-mktg-btn-shadow-focus: rgb(0 0 0 / 15%) 0 0 0 4px;
    --color-mktg-btn-shadow-hover: 0 3px 2px rgba(0, 0, 0, 0.07), 0 7px 5px rgba(0, 0, 0, 0.04), 0 12px 10px rgba(0, 0, 0, 0.03), 0 22px 18px rgba(0, 0, 0, 0.03), 0 42px 33px rgba(0, 0, 0, 0.02), 0 100px 80px rgba(0, 0, 0, 0.02);
    --color-mktg-btn-shadow-hover-muted: rgb(0 0 0 / 70%) 0 0 0 2px inset;
    --color-control-border-color-emphasis: #20252c;
    --color-avatar-bg: #ffffff;
    --color-avatar-border: rgba(1,4,9,0.8);
    --color-avatar-stack-fade: #acb6c0;
    --color-avatar-stack-fade-more: #ced5dc;
    --color-avatar-child-shadow: 0 0 0 2px rgba(255,255,255,0.8);
    --color-topic-tag-border: #0349b4;
    --color-counter-border: #20252c;
    --color-select-menu-backdrop-border: rgba(0,0,0,0);
    --color-select-menu-tap-highlight: rgba(172,182,192,0.5);
    --color-select-menu-tap-focus-bg: #9cd7ff;
    --color-overlay-shadow: 0 1px 3px rgba(1,4,9,0.12), 0 8px 24px rgba(52,59,67,0.12);
    --color-header-text: rgba(255,255,255,0.7);
    --color-header-bg: #0e1116;
    --color-header-divider: #acb6c0;
    --color-header-logo: #ffffff;
    --color-header-search-bg: #0e1116;
    --color-header-search-border: #4b535d;
    --color-sidenav-selected-bg: #ffffff;
    --color-menu-bg-active: rgba(0,0,0,0);
    --color-input-disabled-bg: rgba(172,182,192,0.2);
    --color-timeline-badge-bg: #e7ecf0;
    --color-ansi-black: #0e1116;
    --color-ansi-black-bright: #4b535d;
    --color-ansi-white: #66707b;
    --color-ansi-white-bright: #88929d;
    --color-ansi-gray: #66707b;
    --color-ansi-red: #a0111f;
    --color-ansi-red-bright: #86061d;
    --color-ansi-green: #024c1a;
    --color-ansi-green-bright: #055d20;
    --color-ansi-yellow: #3f2200;
    --color-ansi-yellow-bright: #4e2c00;
    --color-ansi-blue: #0349b4;
    --color-ansi-blue-bright: #1168e3;
    --color-ansi-magenta: #622cbc;
    --color-ansi-magenta-bright: #844ae7;
    --color-ansi-cyan: #1b7c83;
    --color-ansi-cyan-bright: #3192aa;
    --color-btn-text: #0e1116;
    --color-btn-bg: #e7ecf0;
    --color-btn-border: rgba(1,4,9,0.8);
    --color-btn-shadow: 0 1px 0 rgba(1,4,9,0.04);
    --color-btn-inset-shadow: inset 0 1px 0 rgba(255,255,255,0.25);
    --color-btn-hover-bg: #ced5dc;
    --color-btn-hover-border: rgba(1,4,9,0.8);
    --color-btn-active-bg: #acb6c0;
    --color-btn-active-border: rgba(1,4,9,0.8);
    --color-btn-selected-bg: #acb6c0;
    --color-btn-counter-bg: rgba(1,4,9,0.08);
    --color-btn-primary-text: #ffffff;
    --color-btn-primary-bg: #055d20;
    --color-btn-primary-border: #013d14;
    --color-btn-primary-shadow: 0 1px 0 rgba(1,4,9,0.1);
    --color-btn-primary-inset-shadow: inset 0 1px 0 rgba(255,255,255,0.03);
    --color-btn-primary-hover-bg: #024c1a;
    --color-btn-primary-hover-border: #013d14;
    --color-btn-primary-selected-bg: hsla(139,95%,13%,1);
    --color-btn-primary-selected-shadow: inset 0 1px 0 rgba(0,35,11,0.2);
    --color-btn-primary-disabled-text: rgba(255,255,255,0.8);
    --color-btn-primary-disabled-bg: #94d3a2;
    --color-btn-primary-disabled-border: rgba(1,4,9,0.8);
    --color-btn-primary-icon: rgba(255,255,255,0.8);
    --color-btn-primary-counter-bg: rgba(0,35,11,0.2);
    --color-btn-outline-text: #023b95;
    --color-btn-outline-hover-text: #ffffff;
    --color-btn-outline-hover-bg: #0349b4;
    --color-btn-outline-hover-border: #022f7a;
    --color-btn-outline-hover-shadow: 0 1px 0 rgba(1,4,9,0.1);
    --color-btn-outline-hover-inset-shadow: inset 0 1px 0 rgba(255,255,255,0.03);
    --color-btn-outline-hover-counter-bg: rgba(255,255,255,0.2);
    --color-btn-outline-selected-text: #ffffff;
    --color-btn-outline-selected-bg: #022f7a;
    --color-btn-outline-selected-border: #022f7a;
    --color-btn-outline-selected-shadow: inset 0 1px 0 rgba(2,26,74,0.2);
    --color-btn-outline-disabled-text: rgba(3,73,180,0.5);
    --color-btn-outline-disabled-bg: #e7ecf0;
    --color-btn-outline-disabled-counter-bg: rgba(3,73,180,0.05);
    --color-btn-outline-counter-bg: #0969da1a;
    --color-btn-outline-counter-fg: #023b95;
    --color-btn-outline-hover-counter-fg: #ffffff;
    --color-btn-outline-disabled-counter-fg: rgba(3,73,180,0.5);
    --color-btn-danger-text: #86061d;
    --color-btn-danger-hover-text: #ffffff;
    --color-btn-danger-hover-bg: #a0111f;
    --color-btn-danger-hover-border: #6e011a;
    --color-btn-danger-hover-shadow: 0 1px 0 rgba(1,4,9,0.1);
    --color-btn-danger-hover-inset-shadow: inset 0 1px 0 rgba(255,255,255,0.03);
    --color-btn-danger-hover-counter-bg: rgba(255,255,255,0.2);
    --color-btn-danger-selected-text: #ffffff;
    --color-btn-danger-selected-bg: #6e011a;
    --color-btn-danger-selected-border: #6e011a;
    --color-btn-danger-selected-shadow: inset 0 1px 0 rgba(67,0,17,0.2);
    --color-btn-danger-disabled-text: rgba(160,17,31,0.5);
    --color-btn-danger-disabled-bg: #e7ecf0;
    --color-btn-danger-disabled-counter-bg: rgba(160,17,31,0.05);
    --color-btn-danger-counter-bg: rgba(160,17,31,0.1);
    --color-btn-danger-icon: #86061d;
    --color-btn-danger-hover-icon: #ffffff;
    --color-btn-danger-counter-fg: #86061d;
    --color-btn-danger-hover-counter-fg: #ffffff;
    --color-btn-danger-disabled-counter-fg: rgba(160,17,31,0.5);
    --color-underlinenav-icon: #66707b;
    --color-underlinenav-border-hover: rgba(172,182,192,0.2);
    --color-action-list-item-inline-divider: #88929d;
    --color-action-list-item-default-hover-bg: #e7ecf0;
    --color-action-list-item-default-hover-border: #88929d;
    --color-action-list-item-default-active-bg: #ced5dc;
    --color-action-list-item-default-active-border: #20252c;
    --color-action-list-item-default-selected-bg: #ced5dc;
    --color-action-list-item-danger-hover-bg: #a0111f;
    --color-action-list-item-danger-active-bg: #6e011a;
    --color-action-list-item-danger-hover-text: #ffffff;
    --color-switch-track-bg: #66707b;
    --color-switch-track-hover-bg: hsla(211,9%,47%,1);
    --color-switch-track-active-bg: hsla(211,9%,49%,1);
    --color-switch-track-disabled-bg: #88929d;
    --color-switch-track-fg: #ffffff;
    --color-switch-track-disabled-fg: #ffffff;
    --color-switch-track-border: rgba(0,0,0,0);
    --color-switch-track-checked-bg: #0349b4;
    --color-switch-track-checked-hover-bg: #0860CA;
    --color-switch-track-checked-active-bg: #0757BA;
    --color-switch-track-checked-fg: #ffffff;
    --color-switch-track-checked-disabled-fg: #ffffff;
    --color-switch-track-checked-border: rgba(0,0,0,0);
    --color-switch-knob-bg: #ffffff;
    --color-switch-knob-disabled-bg: #e7ecf0;
    --color-switch-knob-border: #20252c;
    --color-switch-knob-checked-bg: #ffffff;
    --color-switch-knob-checked-disabled-bg: #e7ecf0;
    --color-switch-knob-checked-border: #0349b4;
    --color-segmented-control-bg: #e7ecf0;
    --color-segmented-control-button-bg: #ffffff;
    --color-segmented-control-button-hover-bg: rgba(172,182,192,0.2);
    --color-segmented-control-button-active-bg: rgba(172,182,192,0.4);
    --color-segmented-control-button-selected-border: #88929d;
    --color-tree-view-item-chevron-hover-bg: #ced5dc;
    --color-tree-view-item-directory-fill: #368cf9;
    --color-fg-default: #0e1116;
    --color-fg-muted: #0e1116;
    --color-fg-subtle: #66707b;
    --color-fg-on-emphasis: #ffffff;
    --color-canvas-default: #ffffff;
    --color-canvas-overlay: #ffffff;
    --color-canvas-inset: #ffffff;
    --color-canvas-subtle: #e7ecf0;
    --color-border-default: #20252c;
    --color-border-muted: #88929d;
    --color-border-subtle: rgba(1,4,9,0.8);
    --color-shadow-small: 0 1px 0 rgba(1,4,9,0.04);
    --color-shadow-medium: 0 3px 6px rgba(136,146,157,0.15);
    --color-shadow-large: 0 8px 24px rgba(136,146,157,0.2);
    --color-shadow-extra-large: 0 12px 28px rgba(136,146,157,0.3);
    --color-neutral-emphasis-plus: #0e1116;
    --color-neutral-emphasis: #66707b;
    --color-neutral-muted: rgba(172,182,192,0.2);
    --color-neutral-subtle: #e7ecf0;
    --color-accent-fg: #0349b4;
    --color-accent-emphasis: #0349b4;
    --color-accent-muted: #368cf9;
    --color-accent-subtle: #dff7ff;
    --color-success-fg: #055d20;
    --color-success-emphasis: #055d20;
    --color-success-muted: #26a148;
    --color-success-subtle: #d2fedb;
    --color-attention-fg: #744500;
    --color-attention-emphasis: #744500;
    --color-attention-muted: #b58407;
    --color-attention-subtle: #fcf7be;
    --color-severe-fg: #873800;
    --color-severe-emphasis: #873800;
    --color-severe-muted: #dc6d1a;
    --color-severe-subtle: #fff2d5;
    --color-danger-fg: #d1242f;
    --color-danger-emphasis: #a0111f;
    --color-danger-muted: #ee5a5d;
    --color-danger-subtle: #fff0ee;
    --color-open-fg: #055d20;
    --color-open-emphasis: #055d20;
    --color-open-muted: rgba(38,161,72,0.4);
    --color-open-subtle: #d2fedb;
    --color-closed-fg: #d1242f;
    --color-closed-emphasis: #a0111f;
    --color-closed-muted: rgba(238,90,93,0.4);
    --color-closed-subtle: #fff0ee;
    --color-done-fg: #622cbc;
    --color-done-emphasis: #622cbc;
    --color-done-muted: #a371f7;
    --color-done-subtle: #faf0fe;
    --color-sponsors-fg: #971368;
    --color-sponsors-emphasis: #971368;
    --color-sponsors-muted: #ed4baf;
    --color-sponsors-subtle: #feeff7;
    --color-primer-fg-disabled: #88929d;
    --color-primer-canvas-backdrop: rgba(1,4,9,0.5);
    --color-primer-canvas-sticky: rgba(255,255,255,0.95);
    --color-primer-border-active: #ef5b48;
    --color-primer-border-contrast: rgba(1,4,9,0.1);
    --color-primer-shadow-highlight: inset 0 1px 0 rgba(255,255,255,0.25);
    --color-primer-shadow-inset: inset 0 1px 0 rgba(206,213,220,0.2);
    --color-scale-black: #010409;
    --color-scale-white: #ffffff;
    --color-scale-gray-0: #ffffff;
    --color-scale-gray-1: #e7ecf0;
    --color-scale-gray-2: #ced5dc;
    --color-scale-gray-3: #acb6c0;
    --color-scale-gray-4: #88929d;
    --color-scale-gray-5: #66707b;
    --color-scale-gray-6: #4b535d;
    --color-scale-gray-7: #343b43;
    --color-scale-gray-8: #20252c;
    --color-scale-gray-9: #0e1116;
    --color-scale-blue-0: #dff7ff;
    --color-scale-blue-1: #9cd7ff;
    --color-scale-blue-2: #67b3fd;
    --color-scale-blue-3: #368cf9;
    --color-scale-blue-4: #1168e3;
    --color-scale-blue-5: #0349b4;
    --color-scale-blue-6: #023b95;
    --color-scale-blue-7: #022f7a;
    --color-scale-blue-8: #032563;
    --color-scale-blue-9: #021a4a;
    --color-scale-green-0: #d2fedb;
    --color-scale-green-1: #82e596;
    --color-scale-green-2: #43c663;
    --color-scale-green-3: #26a148;
    --color-scale-green-4: #117f32;
    --color-scale-green-5: #055d20;
    --color-scale-green-6: #024c1a;
    --color-scale-green-7: #013d14;
    --color-scale-green-8: #003110;
    --color-scale-green-9: #00230b;
    --color-scale-yellow-0: #fcf7be;
    --color-scale-yellow-1: #f0ce53;
    --color-scale-yellow-2: #d5a824;
    --color-scale-yellow-3: #b58407;
    --color-scale-yellow-4: #956400;
    --color-scale-yellow-5: #744500;
    --color-scale-yellow-6: #603700;
    --color-scale-yellow-7: #4e2c00;
    --color-scale-yellow-8: #3f2200;
    --color-scale-yellow-9: #2e1800;
    --color-scale-orange-0: #fff2d5;
    --color-scale-orange-1: #ffc67b;
    --color-scale-orange-2: #f99636;
    --color-scale-orange-3: #dc6d1a;
    --color-scale-orange-4: #b45105;
    --color-scale-orange-5: #873800;
    --color-scale-orange-6: #702c00;
    --color-scale-orange-7: #5b2300;
    --color-scale-orange-8: #491b00;
    --color-scale-orange-9: #361200;
    --color-scale-red-0: #fff0ee;
    --color-scale-red-1: #ffc1bc;
    --color-scale-red-2: #ff8e8a;
    --color-scale-red-3: #ee5a5d;
    --color-scale-red-4: #d5232c;
    --color-scale-red-5: #a0111f;
    --color-scale-red-6: #86061d;
    --color-scale-red-7: #6e011a;
    --color-scale-red-8: #5a0016;
    --color-scale-red-9: #430011;
    --color-scale-purple-0: #faf0fe;
    --color-scale-purple-1: #e0c5ff;
    --color-scale-purple-2: #c49bff;
    --color-scale-purple-3: #a371f7;
    --color-scale-purple-4: #844ae7;
    --color-scale-purple-5: #622cbc;
    --color-scale-purple-6: #512598;
    --color-scale-purple-7: #411d7b;
    --color-scale-purple-8: #341763;
    --color-scale-purple-9: #260f49;
    --color-scale-pink-0: #feeff7;
    --color-scale-pink-1: #ffbde0;
    --color-scale-pink-2: #fc87ca;
    --color-scale-pink-3: #ed4baf;
    --color-scale-pink-4: #c9248e;
    --color-scale-pink-5: #971368;
    --color-scale-pink-6: #7d0c57;
    --color-scale-pink-7: #660847;
    --color-scale-pink-8: #53043a;
    --color-scale-pink-9: #3e022b;
    --color-scale-coral-0: #fff0ed;
    --color-scale-coral-1: #ffc2b6;
    --color-scale-coral-2: #ff8f7e;
    --color-scale-coral-3: #ef5b48;
    --color-scale-coral-4: #cd3425;
    --color-scale-coral-5: #9f1710;
    --color-scale-coral-6: #870706;
    --color-scale-coral-7: #6f0107;
    --color-scale-coral-8: #5b0002;
    --color-scale-coral-9: #430200;
  }
}
